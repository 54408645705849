(function($){
    function layerslider_talk_to_us_button() {
        var window_width = $(window).width();
        if (window_width > 767) {
            var alignment_target = $('.custom-logo').position();
            var green_button = $('#ls_talk_to_us_btn');
            green_button.css('margin-left', alignment_target.left + 'px');
        }
    }
    $(window).on("load resize",function(e){
        layerslider_talk_to_us_button();
    });
    layerslider_talk_to_us_button();

    var phone_btn = $("#ls_talk_to_us_btn").offset().top; //gets offset of header
    $(window).scroll(function(){
        if($(window).scrollTop() > phone_btn){
            $('#ls_talk_to_us_btn').addClass('phone_fixed');
            $('#layerslider_with_tyre_search').css('position', 'initial');
        }
        else{
            $('#ls_talk_to_us_btn').removeClass('phone_fixed');
            $('#layerslider_with_tyre_search').css('position', '');
        }
    });
})(jQuery);